import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setMainPageClass} from '../../store/appSlice'

const NotFound = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setMainPageClass('page-404'))
        return () => {
            dispatch(setMainPageClass(null))
        }
    }, [])

    return <div className="page-content">
        <svg className="bg-404" width="706" height="745" viewBox="0 0 706 745" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m107.43.61 597.62 160.13L598.23 559.4.61 399.26z"></path>
            <path d="m502.02 321.61 92.13 343.81-293.41 78.62-92.13-343.81z"></path>
            <path d="M.6 160.74 598.24.61l106.81 398.65-597.61 160.13z"></path>
        </svg>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <div className="text-muted mb-2 pb-1">ERROR 404 - NOT FOUND</div>
                    <h2 className="mb-5 text-primary">
                        Your journey is&nbsp;far from over... There's&nbsp;still treasure to&nbsp;uncover.
                    </h2>
                    <figure className="page-404__quote mb-3">
                        <div className="page-404__quote-person d-lg-none">
                            <img src="/static/img/quote-athor-img.jpg" alt="Steve Jobs"/>
                        </div>
                        <svg className="icon-base-white mb-2" viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M1 6.75C1 5.83 1.72 5 2.71 5h1.23c4.4 0 8.1 3.34 8.1 7.6v4.65c0 .92-.73 1.75-1.72 1.75H7.88c-1 0-1.72-.83-1.72-1.75V12.6c0-1.04-.93-2-2.22-2H2.7C1.72 10.6 1 9.77 1 8.85v-2.1ZM3 7v1.6h.94c2.26 0 4.22 1.72 4.22 4V17h1.87v-4.4c0-3.03-2.66-5.6-6.1-5.6H3Zm8.97-.25c0-.92.72-1.75 1.71-1.75h1.22c4.4 0 8.1 3.34 8.1 7.6v4.65c0 .92-.72 1.75-1.71 1.75h-2.45c-.99 0-1.71-.83-1.71-1.75V12.6c0-1.04-.93-2-2.23-2h-1.22c-.99 0-1.71-.83-1.71-1.75v-2.1Zm2 .25v1.6h.93c2.27 0 4.23 1.72 4.23 4V17H21v-4.4C21 9.57 18.34 7 14.9 7h-.93Z"></path>
                        </svg>
                        <blockquote className="pt-1">
                            <p className="h4">But I must explain to you how all this mistaken idea of denouncing
                                pleasure and praising pain was born and I will give you a complete account of the
                                system...</p>
                        </blockquote>
                        <figcaption className="text-end text-muted">—&nbsp; Steve Jobs</figcaption>
                    </figure>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xl-4">
                            <Link to={'/gifts'} className={'btn btn-primary w-100'}>Gift me</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 offset-lg-2 d-none d-lg-block">
                    <div className="page-404__quote-person">
                        <img src="/static/img/quote-athor-img.jpg" alt="Steve Jobs"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default NotFound
